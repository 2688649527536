import { Error } from "../types/Error";

export const SET_ERRORS = "SET_ERRORS";
export const WIPE_ERRORS = "WIPE_ERRORS";

export const setErrors = (errors: Error[]) => ({
    type: SET_ERRORS,
    errors
})

export const wipeErrors = () => ({
    type: WIPE_ERRORS
})