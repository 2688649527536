import { getToken, toggleBackdropSpinner, generateUrl, routes } from "pact.drural.app-skeleton";
import { openNotification } from "pact.drural.notifications";
import { generateQuery, SearchRequest } from "pact.drural.ui";
import { setErrors } from "./errorActions";

export const START_LOAD_REFERRALS = "START_LOAD_REFERRALS";
export const SET_REFERRALS = "SET_REFERRALS";
export const SET_REFERRAL = "SET_REFERRAL";
export const ADD_MESSAGE = "ADD_MESSAGE";
export const WIPE_MESSAGE = "WIPE_MESSAGE";

export const createReferral = (customerEmail: string, serviceId: string, message: string) => (dispatch: any) => {
    dispatch(toggleBackdropSpinner());

    return getToken()
        .then(token => {
            return fetch((generateUrl(routes.referral.v1.create)), { 
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                },
                body: JSON.stringify({
                    to: serviceId,
                    subject: customerEmail,
                    message: message
                })
            }
            );
        })
        .then(response => {
            if(response.status !== 201 && response.status !== 200) {
                dispatch(setErrors([{ message: "REFERRAL_ACTION_FAILED", severity: "error" }]))
            } else {
                return response.json();
            }
        }
        )
        .then(() => {
            dispatch(openNotification("Referral sent!"));
        }
        )
        .then(() => {
            setTimeout(() => {
                window.location.href = "/";
            }, 500);
        })
        .catch(() => {
            dispatch(setErrors([{ message: "REFERRAL_ACTION_FAILED", severity: "error" }]))
        })
        .finally(() => {
            dispatch(toggleBackdropSpinner());
        });
};

export const loadReferrals = (request: SearchRequest) => (dispatch: any) => {
    dispatch(startLoadReferrals());

    return getToken()
        .then(token => {
            return fetch((generateUrl(routes.referral.v1.list) + generateQuery(request)), { 
                method: "GET",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                }
            }
            );
        })
        .then(response => 
        {
            if(response.status !== 200) {
                dispatch(setErrors([{ message: "REFERRAL_ACTION_FAILED", severity: "error" }]))
            } else {
                return response.json();
            }
        })
        .then(json => {
            dispatch(setReferrals(json));
        })
        .catch(() => {
            dispatch(setErrors([{ message: "REFERRAL_ACTION_FAILED", severity: "error" }]))
        });
};

const startLoadReferrals = () => ({
    type: START_LOAD_REFERRALS
});

const setReferrals = (result: any) => ({
    type: SET_REFERRALS,
    result
});

export const loadReferral = (referralId: string) => (dispatch: any) => {
    dispatch(toggleBackdropSpinner());

    return getToken()
        .then(token => {
            return fetch((generateUrl(routes.referral.v1.referral, { referralId: referralId })), { 
                method: "GET",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                }
            }
            );
        })
        .then(response => 
        {
            if(response.status !== 200) {
                dispatch(setErrors([{ message: "REFERRAL_ACTION_FAILED", severity: "error" }]))
            } else {
                return response.json();
            }
        })
        .then(json => {
            dispatch(setReferral(json.referral));
        })
        .catch(() => {
            dispatch(setErrors([{ message: "REFERRAL_ACTION_FAILED", severity: "error" }]))
        })
        .finally(() => {
            dispatch(toggleBackdropSpinner());
        });
};

const setReferral = (referral: any) => ({
    type: SET_REFERRAL,
    referral
});

export const acceptReferral = (referralId: string) => (dispatch: any) => {
    dispatch(toggleBackdropSpinner());

    return getToken()
        .then(token => {
            return fetch((generateUrl(routes.referral.v1.accept, { referralId: referralId })), { 
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                }
            }
            );
        })
        .then(response => 
        {
            if(response.status !== 200) {
                dispatch(setErrors([{ message: "REFERRAL_ACTION_FAILED", severity: "error" }]))
            }
        })
        .then(() => {
            dispatch(openNotification("Referral accepted!"));
            dispatch(loadReferral(referralId));
        })
        .catch(() => {
            dispatch(setErrors([{ message: "REFERRAL_ACTION_FAILED", severity: "error" }]))
        })
        .finally(() => {
            dispatch(toggleBackdropSpinner());
        });
};

export const cancelReferral = (referralId: string) => (dispatch: any) => {
    dispatch(toggleBackdropSpinner());

    return getToken()
        .then(token => {
            return fetch((generateUrl(routes.referral.v1.cancel, { referralId: referralId })), { 
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                }
            }
            );
        })
        .then(response => 
        {
            if(response.status !== 200) {
                dispatch(setErrors([{ message: "REFERRAL_ACTION_FAILED", severity: "error" }]))
            }
        })
        .then(() => {
            dispatch(openNotification("Referral cancelled!"));
            dispatch(loadReferral(referralId));
        })
        .catch(() => {
            dispatch(setErrors([{ message: "REFERRAL_ACTION_FAILED", severity: "error" }]))
        })
        .finally(() => {
            dispatch(toggleBackdropSpinner());
        });
};

export const completeReferral = (referralId: string) => (dispatch: any) => {
    dispatch(toggleBackdropSpinner());

    return getToken()
        .then(token => {
            return fetch((generateUrl(routes.referral.v1.complete, { referralId: referralId })), { 
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                }
            }
            );
        })
        .then(response => 
        {
            if(response.status !== 200) {
                dispatch(setErrors([{ message: "REFERRAL_ACTION_FAILED", severity: "error" }]))
            }
        })
        .then(() => {
            dispatch(openNotification("Referral completed!"));
            dispatch(loadReferral(referralId));
        })
        .catch(() => {
            dispatch(setErrors([{ message: "REFERRAL_ACTION_FAILED", severity: "error" }]))
        })
        .finally(() => {
            dispatch(toggleBackdropSpinner());
        });
};

export const rejectReferral = (referralId: string, message: string) => (dispatch: any) => {
    dispatch(toggleBackdropSpinner());

    return getToken()
        .then(token => {
            return fetch((generateUrl(routes.referral.v1.reject, { referralId: referralId })), { 
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                },
                body: JSON.stringify({
                    message: message
                })
            }
            );
        })
        .then(response => 
        {
            if(response.status !== 200) {
                dispatch(setErrors([{ message: "REFERRAL_ACTION_FAILED", severity: "error" }]))
            }
        })
        .then(() => {
            dispatch(openNotification("Referral rejected!"));
            dispatch(loadReferral(referralId));
        })
        .catch(() => {
            dispatch(setErrors([{ message: "REFERRAL_ACTION_FAILED", severity: "error" }]))
        })
        .finally(() => {
            dispatch(toggleBackdropSpinner());
        });
};


export const addMessage = (message: string) => ({
    type: ADD_MESSAGE,
    message
});

const wipeMessage = () => ({
    type: WIPE_MESSAGE
});

export const submitMessage = (referralId: string, message: string) => (dispatch: any) => {
    dispatch(toggleBackdropSpinner());

    return getToken()
        .then(token => {
            return fetch((generateUrl(routes.referral.v1.message, { referralId: referralId })), { 
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                },
                body: JSON.stringify({
                    message: message
                })
            }
            );
        })
        .then(response => {
            if(response.status !== 200) {
                dispatch(setErrors([{ message: "REFERRAL_ACTION_FAILED", severity: "error" }]))
            }
        }
        )
        .then(() => dispatch(loadReferral(referralId)))
        .then(() => {
            dispatch(wipeMessage());
            dispatch(openNotification("Message sent!"));
        })
        .catch(() => {
            dispatch(setErrors([{ message: "REFERRAL_ACTION_FAILED", severity: "error" }]))
        })
        .finally(() => {
            dispatch(toggleBackdropSpinner());
        });
};