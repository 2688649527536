import { SET_REFERRALS, SET_REFERRAL, ADD_MESSAGE, WIPE_MESSAGE, START_LOAD_REFERRALS } from "../actions/referralActions";

const defaultState = {
    list: {
        items: [],
        selectedItems: [],
        totalCount: 0,
        pageInfo: { },
        isLoading: false
    },
    selectedReferral: null,
    newMessage: "",
    error: null
};

const referralReducer = (state = defaultState, action)=> {
    switch (action.type) {
    case START_LOAD_REFERRALS:
        return {
            ...state,
            list: {
                ...state.list,
                isLoading: true
            }
        };
    case SET_REFERRALS:
        return {
            ...state,
            list: {
                ...state.list,
                items: action.result.referrals,
                totalCount: action.result.totalCount,
                pageInfo: action.result.pageInfo,
                isLoading: false
            }
        };
    case SET_REFERRAL:
        return Object.assign({}, state, {
            selectedReferral: action.referral
        });
    case ADD_MESSAGE:
        return Object.assign({}, state, {
            newMessage: action.message
        });
    case WIPE_MESSAGE:
        return Object.assign({}, state, {
            newMessage: ""
        });
    default:
        return state;
    }
};

export default referralReducer;