import {
  Button,
  Grid,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { SearchRequest, FilterableList, ListState } from "pact.drural.ui";

import { loadCustomers } from "../actions/customerActions";
import {
  NavigationOptions,
  setNavigationOptions,
  translate,
  useTranslations
} from "pact.drural.app-skeleton";
import { Customer } from "../types/Customer";
import { CustomerState } from "src/types/state/CustomerState";
import AddCustomerModal from "./modals/AddCustomerModal";

const NavigationOptionsImpl = () =>
  ({
    isBackEnabled: true,
    isContinueEnabled: false,
    onBackClick: () => (window.location.href = "/")
  } as NavigationOptions);

interface Props {
  setNavigation: (options: NavigationOptions) => void;
  loadCustomers: (request: SearchRequest) => void;
  state: ListState<Customer>;
  selectCustomer: (customer: Customer) => void;
}

const CustomerList = (props: Props) => {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isMounted, setMounted] = useState(false);
  const [isAddCustomerModalOpen, setAddCustomerModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [lastSearch, setLastSearch] = useState("");

  const t = useTranslations();

  useEffect(() => {
    if (!isMounted) {
      props.setNavigation(NavigationOptionsImpl());
      props.loadCustomers({ first: rowsPerPage } as SearchRequest);

      setMounted(true);
    }

    if (searchTerm !== lastSearch) {
      const delaySearchTimeout = setTimeout(() => {
        props.loadCustomers({
          first: rowsPerPage,
          search: searchTerm
        } as SearchRequest);
        setLastSearch(searchTerm);
      }, 300);

      return () => clearTimeout(delaySearchTimeout);
    }
  }, [
    props,
    isMounted,
    setMounted,
    rowsPerPage,
    searchTerm,
    lastSearch,
    setLastSearch
  ]);

  return (
    <>
      <Grid container style={{ marginTop: "-4rem", marginBottom: "4rem" }}>
        <Grid item md={10} xs={6}>
          <Typography variant="h2">
            {translate("REFERRAL_CUSTOMER_SELECTION_HEADER", t)}
          </Typography>
        </Grid>
        <Grid item md={2} xs={6}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setAddCustomerModalOpen(true)}
          >
            {translate("REFERRAL_SEND_BY_EMAIL", t)}
          </Button>
          <AddCustomerModal
            referByEmail={(email: string) =>
              (window.location.href = "/service-selection?cmail=" + email)
            }
            open={isAddCustomerModalOpen}
            onClose={() => setAddCustomerModalOpen(false)}
          />
        </Grid>
      </Grid>

      <FilterableList
        state={props.state}
        label={translate("CUSTOMER_SELECTION_SEARCH", t)}
        getTranslation={(key: string) => translate(key, t)}
        columnCount={5}
        onSearch={(rowsPerPage, searchQuery) => {
          setRowsPerPage(rowsPerPage);
          setSearchTerm(searchQuery);
        }}
        onPageChange={(page, currentPage, rowsPerPage, searchQuery) => {
          setRowsPerPage(rowsPerPage);

          if (page > currentPage) {
            props.loadCustomers({
              first: rowsPerPage,
              after: props.state.pageInfo.endCursor,
              search: searchQuery
            } as SearchRequest);
          } else {
            props.loadCustomers({
              last: rowsPerPage,
              before: props.state.pageInfo.startCursor,
              search: searchQuery
            } as SearchRequest);
          }
        }}
        onRowsPerPageChange={(rowsPerPage, searchQuery) => {
          setRowsPerPage(rowsPerPage);

          props.loadCustomers({
            first: rowsPerPage,
            search: searchQuery
          } as SearchRequest);
        }}
        tableHead={
          <TableHead>
            <TableRow>
              <TableCell style={{ width: "50%" }}>
                {translate("REFERRAL_CUSTOMER_NAME", t)}
              </TableCell>
              <TableCell style={{ width: "50%" }}>
                {translate("REFERRAL_CUSTOMER_EMAIL", t)}
              </TableCell>
            </TableRow>
          </TableHead>
        }
        tableBody={
          <TableBody>
            {props.state.items.map((customer, i) => {
              return (
                <TableRow
                  key={i}
                  onClick={() =>
                    (window.location.href =
                      "/service-selection?cid=" + customer.id)
                  }
                >
                  <TableCell>
                    <Typography>
                      {customer.firstName} {customer.lastName}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{customer.email}</Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        }
      />
    </>
  );
};

const mapStateToProps = (state: { customer: CustomerState }) => {
  return {
    state: state.customer.list
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setNavigation: (options: NavigationOptions) => {
      dispatch(setNavigationOptions(options));
    },
    loadCustomers: (request: SearchRequest) => {
      dispatch(loadCustomers(request));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);
