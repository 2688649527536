import { SET_CUSTOMERS, SET_CUSTOMER, SELECT_CUSTOMER, UNSELECT_CUSTOMER, START_LOAD_CUSTOMERS } from "../actions/customerActions";

const defaultState = {
    list: {
        items: [],
        selectedItems: [],
        totalCount: 0,
        pageInfo: { },
        isLoading: false
    },
    selectedCustomerId: -1,
    selectedCustomerDetails: null
};

const customerReducer = (state = defaultState, action)=> {
    switch (action.type) {
        case START_LOAD_CUSTOMERS:
            return {
                ...state,
                list: {
                    ...state.list,
                    isLoading: true
                }
            };
        case SET_CUSTOMERS:
            return {
                ...state,
                list: {
                    ...state.list,
                    items: action.result.customers,
                    totalCount: action.result.totalCount,
                    pageInfo: action.result.pageInfo,
                    isLoading: false
                }
            };
        case SET_CUSTOMER:
            let customer = action.customer;
            customer.dateOfBirth = new Date(customer.dateOfBirth);
                
            return {
                ...state,
                selectedCustomerDetails: action.customer
            };
        case SELECT_CUSTOMER:
            return Object.assign({}, state, {
                selectedCustomerId: action.customerId
            });
        case UNSELECT_CUSTOMER:
            return Object.assign({}, state, {
                selectedCustomerId: -1
            });
        default:
            return state;
    }
};

export default customerReducer;