import { 
    TOGGLE_ADD_CUSTOMER_MODAL, 
    TOGGLE_REJECT_REFERRAL_MODAL
} from "../actions/modalActions";

const defaultState = {
    isAddCustomerModalOpen: false,
    isRejectReferralModalOpen: false,
};

const modalReducer = (state = defaultState, action)=> {
    switch (action.type) {
    case TOGGLE_ADD_CUSTOMER_MODAL:
        return Object.assign({}, state, {
            isAddCustomerModalOpen: !state.isAddCustomerModalOpen
        });
    case TOGGLE_REJECT_REFERRAL_MODAL:
        return Object.assign({}, state, {
            isRejectReferralModalOpen: !state.isRejectReferralModalOpen
        });
    default:
        return state;
    }
};

export default modalReducer;