import React, { createRef, useEffect, useState } from "react";
import { connect } from "react-redux";

import { 
    Box, 
    Button, 
    CardMedia, 
    Container, 
    Grid,
    Typography 
} from "@material-ui/core";

import DataBox from "./elements/DataBox";

import { setNavigationOptions, translate, useTranslations } from "pact.drural.app-skeleton";
import { getUrlParameter } from "pact.drural.utils";

import { loadCustomer } from "../actions/customerActions";
import { addMessage, createReferral } from "../actions/referralActions";
import { RichTextEditor, loadService } from "pact.drural.ui";
import ReferralSummary from "./ReferralSummary";
import PersonalData from "./PersonalData";
import { Alert } from "@material-ui/lab";

const NavigationOptions =  (props, customerId, customerEmail, serviceId, translations) =>  ({
    isBackEnabled: true,
    isContinueEnabled: true, 
    onBackClick: customerId !== null ? "/service-selection?cid=" + customerId : "/service-selection?cmail=" + customerEmail,
    onContinueClick: () => props.sendReferral(customerEmail, serviceId, props.message),
    continueButtonText: translate("BUTTON_SEND", translations)
});

const CreateReferral = (props) => {
    const [isMounted, setMounted] = useState(false);

    const inputFile = createRef();
    const t = useTranslations();

    useEffect(() => {
        let customerId = getUrlParameter("cid");
        let customerEmail = getUrlParameter("cmail");
        if (customerId === null && customerEmail === null) {
            window.location.href = "/customer-selection";
        }

        if (customerId !== null && !isMounted) {
            props.loadCustomer(customerId);
        }

        if (!isMounted) {
            let serviceId = getUrlParameter("sid");
            props.loadService(serviceId);

            setMounted(true);
        }

        props.setNavigation(
            NavigationOptions(
                props,
                customerId,
                customerEmail !== null ? getUrlParameter("cmail") : props.customer === null ? "" : props.customer.email, 
                getUrlParameter("sid"),
                t
            )
        );
    }, [props, isMounted, setMounted, t]);

    let customerEmail = getUrlParameter("cmail");

    if (props.customer === null && customerEmail === null) {
        return <></>;
    }

    if (props.service === null || props.service === undefined) {
        return <></>;
    }

    return(
        <>
            <Grid container style={{marginTop: "-4rem"}}>
                <Grid item md={9} xs={6}><Typography variant="h2">{ translate("REFERRAL_CREATE_HEADER", t) }</Typography></Grid>
                <Grid item md={3} xs={6}></Grid>
            </Grid>
            {
                props.errors.length > 0 
                    ? <Alert style={{marginTop: "1.5rem"}} severity={props.errors[0].severity}>{ translate(props.errors[0].message, t) }</Alert>
                    : <></>
            }
            <Typography variant="subtitle1" style={{ fontSize: 18, marginTop: "1rem", marginBottom: "2rem"}}>
                {
                    customerEmail !== null 
                        ? customerEmail
                        : ""
                }
            </Typography>
            
            {
                props.customer !== null
                    ? <PersonalData customer={props.customer} />
                    : <></>
            }

            <ReferralSummary referral={{ to: props.service }} />

            <Grid container>
                <DataBox mdGridWidth={12} header={translate("REFERRAL_CREATE_MESSAGE_HEADER", t)} height="60rem" dividerWidth="102%">
                    <RichTextEditor content={props.message} minHeight="50rem" onSave={props.addMessage} placeholder={ translate("RICHTEXT_EDITOR_START_TYPING", t) } />
                </DataBox>
                {/* <DataBox mdGridWidth={4} header="Add background" height="25rem" dividerWidth="106.5%">
                    <FormControl style={{width: "100%", marginBottom: "1rem"}}>
                        <InputLabel>Category</InputLabel>
                        <Select
                            label="Category"
                            value={[]}
                            variant="outlined"
                        >
                            <MenuItem value={0}>Category 1</MenuItem>
                            <MenuItem value={1}>Category 2</MenuItem>
                        </Select>
                    </FormControl>
                    <Grid container style={{margin: "1rem", backgroundColor: "#C4F4E5", width: "95%", borderRadius: 6}}>
                        <Grid item md={10}><Typography variant="h5" style={{marginLeft: "1rem", marginTop: "0.75rem"}}>Overweight</Typography></Grid>
                        <Grid item md={1}><IconButton><Close /></IconButton></Grid>
                    </Grid>
                    <Grid container style={{margin: "1rem", backgroundColor: "#C4F4E5", width: "95%", borderRadius: 6}}>
                        <Grid item md={10}><Typography variant="h5" style={{marginLeft: "1rem", marginTop: "0.75rem"}}>Type 2 Diabetes</Typography></Grid>
                        <Grid item md={1}><IconButton><Close /></IconButton></Grid>
                    </Grid>
                </DataBox>
                <Grid item md={8}></Grid> */}
                {/* <DataBox mdGridWidth={4} header="Add attachment" height="25rem" dividerWidth="106.5%">
                    <input type='file' id='file' ref={inputFile } style={{display: 'none'}}/>
                    <Container align="center">
                        <Box 
                            sx={{ border: '2px dashed grey', width:"15rem", borderRadius: 8, "&:hover": {cursor: "pointer"} }} 
                            onClick={ ()=> inputFile.current.click() }
                        >
                            <CardMedia
                            component="img"
                            image="/f63a0bebef266cb6366981631915929e.png" />
                        </Box>
                    </Container>

                </DataBox> */}
            </Grid>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        customer: state.customer.selectedCustomerDetails,
        message: state.referral.newMessage,
        service: state.service.current,
        errors: state.error.errors
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setNavigation: (options) => {
            dispatch(setNavigationOptions(options));
        },
        sendReferral: (customerEmail, serviceId, message) => {
            dispatch(createReferral(customerEmail, serviceId, message));
        },
        loadCustomer: (customerId) => {
            dispatch(loadCustomer(customerId));
        },
        addMessage: (message) => {
            dispatch(addMessage(message));
        },
        loadService: (serviceId) => {
            dispatch(loadService(serviceId));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateReferral);