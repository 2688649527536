import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import {
  Box,
  Button,
  CardMedia,
  Container,
  Grid,
  MenuItem,
  Select,
  Typography
} from "@material-ui/core";
import { toggleRejectReferralModal } from "../actions/modalActions";
import DataBox from "./elements/DataBox";
import ReferralSummary from "./ReferralSummary";
import RejectReferralModal from "./modals/RejectReferralModal";
import {
  setNavigationOptions,
  translate,
  useTranslations
} from "pact.drural.app-skeleton";
import { getUrlParameter } from "pact.drural.utils";
import {
  acceptReferral,
  addMessage,
  cancelReferral,
  completeReferral,
  loadReferral,
  rejectReferral,
  submitMessage
} from "../actions/referralActions";
import ReferralDetailActions from "./elements/ReferralDetailActions";
import { RichTextEditor, RichTextViewer, loadService } from "pact.drural.ui";
import PersonalData from "./PersonalData";
import { Alert } from "@material-ui/lab";

const NavigationOptions = props => ({
  isBackEnabled: true,
  isContinueVisible: false,
  onBackClick: "/",
  extension: (
    <Grid container style={{ marginTop: "1rem" }}>
      {" "}
      <ReferralDetailActions
        referral={props.referral}
        onCancel={props.cancelReferral}
        onComplete={props.completeReferral}
        onAccept={props.acceptReferral}
        onReject={props.toggleRejectReferralModal}
      />
    </Grid>
  )
});

const ReferralDetails = props => {
  const [isMounted, setMounted] = useState(false);

  const [sortBy, setSortBy] = useState(
    localStorage.getItem("sortMessagesBy") ?? "0"
  );

  const t = useTranslations();

  useEffect(() => {
    let referralId = getUrlParameter("rid");
    if (referralId === null) {
      window.location.href = "/";
    }

    if (!isMounted) {
      props.loadReferral(referralId);
      setMounted(true);
    }

    if (props.referral !== null) {
      props.setNavigation(NavigationOptions(props));
    }
  }, [props, isMounted, setMounted]);

  if (props.errors.length > 0) {
    return (
      <Alert severity={props.errors[0].severity}>
        {translate(props.errors[0].message, t)}
      </Alert>
    );
  }

  if (props.referral === null) {
    return <></>;
  }

  return (
    <>
      <Grid container style={{ marginTop: "-4rem" }}>
        <Grid item md={12} xs={12}>
          <Typography variant="h2">
            {translate("REFERRAL_CREATE_HEADER", t)}
          </Typography>
        </Grid>
      </Grid>
      {props.errors.length > 0 ? (
        <Alert
          style={{ marginTop: "1.5rem" }}
          severity={props.errors[0].severity}
        >
          {translate(props.errors[0].message, t)}
        </Alert>
      ) : (
        <></>
      )}
      <ReferralSummary referral={props.referral} />
      <PersonalData customer={props.referral.subject} />

      <Grid container>
        <DataBox
          mdGridWidth={12}
          header={translate("REFERRAL_OVERVIEW_MESSAGES_HEADER", t)}
          dividerWidth="102%"
          dropdown={
            <Select
              style={{ float: "right", marginRight: "2rem" }}
              label="Sort by"
              placeholder="Sort by"
              value={sortBy}
              onChange={event => {
                localStorage.setItem("sortMessagesBy", event.target.value);
                setSortBy(event.target.value);
              }}
            >
              <MenuItem value={"0"}>Newest on top</MenuItem>
              <MenuItem value={"1"}>Oldest on top</MenuItem>
            </Select>
          }
        >
          {props.referral.messages
            .sort((a, b) => {
              if (sortBy === "0") {
                return new Date(b.timestamp) - new Date(a.timestamp);
              } else {
                return new Date(a.timestamp) - new Date(b.timestamp);
              }
            })
            .map(message => (
              <Box
                key={message.id}
                style={{
                  marginLeft: "1rem",
                  marginBottom: "1.5rem",
                  paddingBottom: "1rem",
                  paddingLeft: ".5rem",
                  backgroundColor: "#E0DEE3",
                  width: "97.5%",
                  borderRadius: 4
                }}
              >
                <Grid container>
                  <Grid item md={12} xs={6} align="left">
                    <Typography
                      variant="subtitle2"
                      style={{
                        paddingTop: "2rem",
                        paddingLeft: "2rem",
                        marginBottom: "-2rem",
                        fontWeight: 600
                      }}
                    >
                      {message.sendBy}
                    </Typography>
                  </Grid>
                  <Grid item md={12} xs={6} align="right">
                    <Typography
                      variant="subtitle2"
                      style={{
                        paddingTop: "0rem",
                        marginBottom: "-2rem",
                        marginRight: "2rem"
                      }}
                    >
                      {new Date(message.timestamp).toLocaleString("nl-NL")}
                    </Typography>
                  </Grid>
                </Grid>
                <RichTextViewer content={message.text} />
              </Box>
            ))}
          <Container
            align="left"
            style={{
              paddingLeft: "0",
              paddingRight: "0",
              marginBottom: "1rem"
            }}
          >
            <RichTextEditor
              content={props.message}
              onSave={props.addMessage}
              minHeight="50rem"
              placeholder={translate("RICHTEXT_EDITOR_START_TYPING", t)}
            />
          </Container>

          <Container align="right">
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                props.sendMessage(getUrlParameter("rid"), props.message)
              }
            >
              {translate("REFERRAL_CREATE_MESSAGE_HEADER", t)}
            </Button>
          </Container>
        </DataBox>
      </Grid>
      <RejectReferralModal
        handleClose={props.toggleRejectReferralModal}
        open={props.isRejectReferralModalOpen}
        referral={props.referral}
        rejectReferral={props.rejectReferral}
      />
    </>
  );
};

const mapStateToProps = state => {
  return {
    isReferralBackdropOpen: state.modal.isReferralBackdropOpen,
    isRejectReferralModalOpen: state.modal.isRejectReferralModalOpen,
    referral: state.referral.selectedReferral,
    message: state.referral.newMessage,
    errors: state.error.errors
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setNavigation: options => {
      dispatch(setNavigationOptions(options));
    },
    cancelReferral: referralId => {
      dispatch(cancelReferral(referralId));
    },
    completeReferral: referralId => {
      dispatch(completeReferral(referralId));
    },
    acceptReferral: referralId => {
      dispatch(acceptReferral(referralId));
    },
    toggleRejectReferralModal: () => {
      dispatch(toggleRejectReferralModal());
    },
    rejectReferral: (referralId, message) => {
      dispatch(rejectReferral(referralId, message));
      dispatch(toggleRejectReferralModal());
    },
    sendMessage: (referralId, message) => {
      dispatch(submitMessage(referralId, message));
    },
    loadReferral: referralId => {
      dispatch(loadReferral(referralId));
    },
    addMessage: message => {
      dispatch(addMessage(message));
    },
    loadService: serviceId => {
      dispatch(loadService(serviceId));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReferralDetails);
