export const ReferralRejectionReason = [
  {
    Reason: "Buiten mijn expertise",
    Code: 0
  },
  {
    Reason: "Geen beschikbaarheid",
    Code: 1
  },
  {
    Reason: "Ander",
    Code: 2
  }
];
