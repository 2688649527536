import { Translation, translate } from "pact.drural.app-skeleton";

export const genderFromEnum = (gender: number, translations: Translation[]) => {
    if (gender === 0) {
        return translate("CUSTOMER_GENDER_OTHER", translations);
    }

    if (gender === 1) {
        return translate("CUSTOMER_GENDER_MALE", translations);
    }

    return translate("CUSTOMER_GENDER_FEMALE", translations);
};