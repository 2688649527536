import React from "react";
import ReactDOM from "react-dom";
import { IntlProvider } from "react-intl";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";


import { createStore, applyMiddleware, compose  } from "redux";
import { Provider } from "react-redux";
import thunkMiddleware from "redux-thunk";

import rootReducer from "./reducers/rootReducer";

import "@fontsource/poppins/300.css";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/600.css";

import ReferralOverview from "./components/ReferralOverview";
import CustomerList from "./components/CustomerList";
import ServiceSelection from "./components/ServiceSelection";
import CreateReferral from "./components/CreateReferral";
import ReferralDetails from "./components/ReferralDetails";

import { AuthenticatedAppSkeleton, Entrypoint } from "pact.drural.app-skeleton";

import { SideBarMenuItemCollection } from "./components/navigation/SideBarMenuItemCollection";
import { DruralThemeProvider } from "pact.drural.ui";


const composeEnhancers =
  typeof window === "object" &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?   
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      : compose;

const enhancer = composeEnhancers(
    applyMiddleware(thunkMiddleware),
);

const store = createStore(
    rootReducer, 
    enhancer
);

ReactDOM.render(
    <Provider store={store}>
        <DruralThemeProvider>
            <IntlProvider
                locale='en'>
                <Router>
                    <Routes>
                        <Route exact path="/entry" element={<Entrypoint />} />
                        <Route exact path="/" element={<AuthenticatedAppSkeleton menuItems={(t) => SideBarMenuItemCollection(t)}><ReferralOverview /></AuthenticatedAppSkeleton>} />
                        <Route exact path="/customer-selection" element={<AuthenticatedAppSkeleton menuItems={(t) => SideBarMenuItemCollection(t)}><CustomerList /></AuthenticatedAppSkeleton>} />
                        <Route exact path="/service-selection" element={<AuthenticatedAppSkeleton menuItems={(t) => SideBarMenuItemCollection(t)}><ServiceSelection /></AuthenticatedAppSkeleton>} />
                        <Route exact path="/referral" element={<AuthenticatedAppSkeleton menuItems={(t) => SideBarMenuItemCollection(t)}><CreateReferral /></AuthenticatedAppSkeleton>} />
                        <Route exact path="/referral-details" element={<AuthenticatedAppSkeleton menuItems={(t) => SideBarMenuItemCollection(t)}><ReferralDetails /></AuthenticatedAppSkeleton>} />
                    </Routes>
                </Router>
            </IntlProvider>
        </DruralThemeProvider>
    </Provider>,
    document.getElementById("root")
);