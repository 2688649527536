import { SET_ERRORS, WIPE_ERRORS } from "../actions/errorActions";
import { ErrorState } from "../types/state/ErrorState";


const defaultState = {
    errors: []
} as ErrorState;

const errorReducer = (state = defaultState, action)=> {
    switch (action.type) {
    case SET_ERRORS:
        return {
            ...state,
            errors: action.errors
        }
    case WIPE_ERRORS:
        return {
            ...state,
            errors: []
        }
    default:
        return state;
    }
};

export default errorReducer;