import React from "react";
import { translate, useTranslations } from "pact.drural.app-skeleton";
import { Box, CardMedia, Container, Grid, Typography, Button } from "@material-ui/core";
import { LocationOn } from "@material-ui/icons";
import ReferralState from "./elements/ReferralState";
import { RichTextViewer } from "pact.drural.ui";

interface ReferralSummaryProps {
    referral: any
}

const ReferralSummary = (props: ReferralSummaryProps) => {
    const t = useTranslations();

    return(
        <>
            <Container style={{padding: "1rem", marginBottom: "4rem"}}>
                <Box sx={{bgcolor: "#FFFFFF", padding: "1.2rem", borderRadius: 8, boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.1)", height: "100%", marginBottom: "2rem"}}>
                    <Grid container>
                        <Grid item md={9} xs={12} style={{padding: ".25rem", paddingLeft:"1rem"}}>
                            <Typography variant="body1" style={{fontSize: 20}}>{ props.referral.to.serviceName }</Typography>
                            <Typography variant="body1" style={{fontSize: 12}}>{ props.referral.to.serviceProvider }</Typography>
                        </Grid>
                        <Grid item md={3} xs={12}>
                            {
                                props.referral.status === undefined
                                    ? <></>
                                    : <Grid container  style={{ marginTop: "1.3rem" }}>
                                        <Grid item md={4} xs={6}><Typography style={{fontWeight: 500}}>{ translate("REFERRAL_STATE", t) }</Typography></Grid>
                                        <Grid item md={8} xs={6}><ReferralState state={props.referral.status} /></Grid>
                                    </Grid>
                            }
                        </Grid>
                    </Grid>
                </Box>

                <Box sx={{bgcolor: "#FFFFFF", padding: "1.2rem", borderRadius: 8, boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.1)", height: "100%", marginBottom: "2rem"}}>
                    <Grid container>
                        <Grid item md={9} xs={12} style={{padding: ".25rem", paddingLeft:"1rem"}}>
                            <Typography variant="body1" style={{fontSize: 20}}>{ translate("SERVICES_SERVICE_DESCRIPTION", t) }</Typography>
                            <div style={{marginLeft: "-2rem"}}><RichTextViewer content={props.referral.to.description} isEditorJS={true} /></div>
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <CardMedia
                                component="img"
                                image={props.referral.to.image}
                                height="200px"
                                style={{width: "min(20vw, 270px)"}}
                            />
                        </Grid>
                    </Grid>
                </Box>

                <Box sx={{bgcolor: "#FFFFFF", padding: "1.2rem", borderRadius: 8, boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.1)", height: "100%"}}>
                    <Grid container>
                        <Grid item md={4} xs={12} style={{padding: ".25rem", paddingLeft:"1rem"}}>
                            <Typography variant="body1" style={{fontSize: 20, fontWeight: 500}}>{ props.referral.to.price === null ? "€ 0,00" : props.referral.to.price }</Typography>
                            <Typography variant="body1" style={{fontSize: 12}}>{ translate("SERVICES_SERVICE_PRICE", t) }</Typography>
                        </Grid>
                        <Grid item md={4}>
                            <div style={{marginRight: "1.5rem", marginTop: "1.5rem"}}>
                                <Typography 
                                    variant="body1" 
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        flexWrap: "wrap",
                                    }}
                                >
                                    <LocationOn /> { props.referral.to.location.street + ", " + props.referral.to.location.postalCode }
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item md={4}>
                            <Button style={{float: "right", marginTop:"1rem", marginRight:"2rem"}} variant="text" color="primary" onClick={() => window.open(props.referral.to.href, "_blank")}>
                                <Typography variant="h5" style={{ color: "#23C290", textDecoration: "underline" }}>{ translate("SERVICES_SERVICE_VIEW_MORE", t) }</Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </>
    );
};

export default ReferralSummary;