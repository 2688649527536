import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { Button, Grid, Typography } from "@material-ui/core";

import { loadCustomer } from "../actions/customerActions";

import { setNavigationOptions, translate, useTranslations } from "pact.drural.app-skeleton";
import { getUrlParameter } from "pact.drural.utils";
import { FilterableServiceList } from "pact.drural.ui";

const NavigationOptions = (props, customerId, customerEmail) =>  ({
    isBackEnabled: true,
    isContinueEnabled: props.selectedServices.length > 0, 
    onBackClick: "/customer-selection",
    onContinueClick: 
        customerId !== null 
            ? "/referral?cid=" + customerId + "&sid=" + props.selectedServices[0] 
            : "/referral?cmail=" + customerEmail + "&sid=" + props.selectedServices[0] 
});

const ServiceSelection = (props) => {
    const [isMounted, setMounted] = useState(false);

    const t = useTranslations();

    useEffect(() => {
        let customerId = getUrlParameter("cid");
        let customerEmail = getUrlParameter("cmail");
        if (customerId === null && customerEmail === null) {
            window.location.href = "/customer-selection";
        }

        if (customerId !== null && props.customer === null && !isMounted) {
            props.loadCustomer(customerId);

            setMounted(true);
        }

        props.setNavigation(NavigationOptions(props, getUrlParameter("cid"), getUrlParameter("cmail")));
    }, [props, isMounted, setMounted]);

    let customerEmail = getUrlParameter("cmail");

    if (props.customer === null && customerEmail === null) {
        return <></>;
    }

    return(
        <>
            <Grid container style={{marginTop: "-4rem"}}>
                <Grid item md={9} xs={6}><Typography variant="h2">{ translate("REFERRAL_SERVICE_SELECTION_HEADER", t) }</Typography></Grid>
                <Grid item md={3} xs={6}>
                    <Button style={{float: "right"}} variant="outlined" color="secondary" onClick={()  => window.location.href = "https://questionnaire.samenkwiek.nu/professionalentry"}>
                        { translate("REFERRAL_SERVICE_SELECTION_FIND_RECOMMENDATIONS", t) }
                    </Button>
                </Grid>
            </Grid>
            <Typography variant="subtitle1" style={{fontSize: 18, marginTop: "1rem", marginBottom: "2rem"}}>
                {
                    customerEmail !== null 
                        ? customerEmail
                        : props.customer.firstName + " " + props.customer.lastName
                }
            </Typography>
            
            <FilterableServiceList mode="single" getTranslation={(key) => translate(key, t)} />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        selectedServices: state.service.list.selectedItems,
        customer: state.customer.selectedCustomerDetails,
        services: state.service.list
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setNavigation: (options) => {
            dispatch(setNavigationOptions(options));
        },
        loadCustomer: (customerId) => {
            dispatch(loadCustomer(customerId, false));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceSelection);