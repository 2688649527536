import {
  Button,
  Grid,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Tabs,
  Tab,
  Box
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { selectCustomer } from "../actions/customerActions";
import ReferralState from "./elements/ReferralState";

import {
  setNavigationOptions,
  translate,
  useTranslations
} from "pact.drural.app-skeleton";
import { loadReferrals } from "../actions/referralActions";
import { ReferralType } from "../enums/ReferralType";
import { FilterableList } from "pact.drural.ui";
import { ReferralStatus } from "../enums/ReferralStatus";
import WaitingForCustomerModal from "./modals/WaitingForCustomerModal";

const NavigationOptions = {
  isBackVisible: false,
  isContinueVisible: false
};

const ReferralOverview = props => {
  const [selectedTab, selectTab] = useState(ReferralType.Received);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isMounted, setMounted] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [lastSearch, setLastSearch] = useState("");
  const [isCustomerFeedbackModalOpen, setCustomerFeedbackModalOpen] =
    useState(false);

  const t = useTranslations();

  useEffect(() => {
    if (!isMounted) {
      props.setNavigation(NavigationOptions);
      props.loadReferrals({ first: rowsPerPage, filter: selectedTab });

      setMounted(true);
    }

    if (searchTerm !== lastSearch) {
      const delaySearchTimeout = setTimeout(() => {
        props.loadReferrals({
          first: rowsPerPage,
          search: searchTerm,
          filter: selectedTab
        });
        setLastSearch(searchTerm);
      }, 300);

      return () => clearTimeout(delaySearchTimeout);
    }
  }, [
    props,
    isMounted,
    rowsPerPage,
    selectedTab,
    searchTerm,
    lastSearch,
    setLastSearch
  ]);

  return (
    <>
      <Grid container style={{ marginTop: "-4rem", marginBottom: "4rem" }}>
        <Grid item md={9} xs={6}>
          <Typography variant="h2">
            {translate("REFERRAL_SELECTION_HEADER", t)}
          </Typography>
        </Grid>
        <Grid item md={3} xs={6}>
          <Button
            style={{ float: "right" }}
            variant="contained"
            color="primary"
            onClick={() => (window.location.href = "/customer-selection")}
          >
            {translate("REFERRAL_NEW_BUTTON", t)}
          </Button>
        </Grid>
      </Grid>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={selectedTab}
          onChange={(_, value) => {
            selectTab(value);
            props.loadReferrals({ first: rowsPerPage, filter: value });
          }}
        >
          <Tab label={translate("REFERRAL_SELECTION_RECEIVED", t)} />
          <Tab label={translate("REFERRAL_SELECTION_SENT", t)} />
        </Tabs>
      </Box>

      <WaitingForCustomerModal
        open={isCustomerFeedbackModalOpen}
        onClose={() => setCustomerFeedbackModalOpen(false)}
      />
      <FilterableList
        state={props.state}
        label={translate("REFERRAL_SELECTION_SEARCH", t)}
        getTranslation={(key: string) => translate(key, t)}
        columnCount={5}
        onSearch={(rowsPerPage, searchQuery) => {
          setRowsPerPage(rowsPerPage);
          setSearchTerm(searchQuery);
        }}
        onPageChange={(page, currentPage, rowsPerPage, searchQuery) => {
          setRowsPerPage(rowsPerPage);

          if (page > currentPage) {
            props.loadReferrals({
              first: rowsPerPage,
              after: props.state.pageInfo.endCursor,
              search: searchQuery,
              filter: selectedTab
            });
          } else {
            props.loadReferrals({
              last: rowsPerPage,
              before: props.state.pageInfo.startCursor,
              search: searchQuery,
              filter: selectedTab
            });
          }
        }}
        onRowsPerPageChange={(rowsPerPage, searchQuery) => {
          setRowsPerPage(rowsPerPage);

          props.loadReferrals({
            first: rowsPerPage,
            search: searchQuery,
            filter: selectedTab
          });
        }}
        tableHead={
          <TableHead>
            <TableRow>
              <TableCell style={{ width: "20%" }}>
                {translate("REFERRAL_CUSTOMER_NAME", t)}
              </TableCell>
              <TableCell style={{ width: "25%" }}>
                {translate("REFERRAL_TO", t)}
              </TableCell>
              <TableCell style={{ width: "25%" }}>
                {selectedTab === ReferralType.Received
                  ? translate("REFERRAL_SENT_BY_SERVICE_PROVIDER", t)
                  : translate("REFERRAL_SERVICE_PROVIDER", t)}
              </TableCell>
              <TableCell style={{ width: "15%" }}>
                {translate("REFERRAL_DATE", t)}
              </TableCell>
              <TableCell style={{ width: "25%" }}>
                {translate("REFERRAL_STATE", t)}
              </TableCell>
            </TableRow>
          </TableHead>
        }
        tableBody={
          <TableBody>
            {props.state.items.map((referral, i) => {
              return (
                <TableRow
                  key={i}
                  style={{
                    backgroundColor:
                      referral.status === ReferralStatus.Pending
                        ? "#FDE6C6"
                        : "#fff"
                  }}
                >
                  <TableCell
                    onClick={() => {
                      if (
                        referral.subject === null ||
                        referral.status === ReferralStatus.NeedsApproval
                      ) {
                        setCustomerFeedbackModalOpen(true);
                      } else {
                        props.selectCustomer(referral.subject.id);
                        window.location.href =
                          "referral-details?rid=" + referral.id;
                      }
                    }}
                  >
                    <Typography>
                      {referral.subject === null
                        ? referral.subjectEmail
                        : referral.subject.firstName +
                          " " +
                          referral.subject.lastName}
                    </Typography>
                  </TableCell>
                  <TableCell
                  // onClick={() => {
                  //         selectServiceDetails(props.services.find(s => s.id === referral.to).id)
                  //     }}>
                  >
                    <Typography>{referral.to.serviceName}</Typography>
                  </TableCell>
                  <TableCell
                    onClick={() =>
                      window.open("https://samenkwiek.nu/", "_blank")
                    }
                  >
                    <Typography>
                      {referral.type === ReferralType.Received
                        ? referral.from.publicName
                        : referral.to.serviceProvider}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {new Date(referral.creationDate).toLocaleDateString(
                      "nl-NL"
                    )}
                  </TableCell>
                  <TableCell>
                    <ReferralState state={referral.status} />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        }
      />
    </>
  );
};

const mapStateToProps = state => {
  return {
    state: state.referral.list
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setNavigation: options => {
      dispatch(setNavigationOptions(options));
    },
    selectCustomer: customerId => {
      dispatch(selectCustomer(customerId));
    },
    loadReferrals: request => {
      dispatch(
        loadReferrals({
          ...request,
          params: [{ key: "rf", value: request.filter }]
        })
      );
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReferralOverview);
