import React from "react";

import { Grid, Typography } from "@material-ui/core";

import { Customer } from "src/types/Customer";
import DataBox from "./elements/DataBox";
import { genderFromEnum } from "../gender";
import { translate, useTranslations } from "pact.drural.app-skeleton";

interface Props {
  customer: Customer;
}

const PersonalData = (props: Props) => {
  const t = useTranslations();

  const address =
    props.customer.addresses.length > 0
      ? props.customer.addresses[0]
      : undefined;

  return (
    <DataBox
      md={12}
      header={translate("CUSTOMER_PERSONAL_DATA_HEADER", t)}
      editLabel={translate("BUTTON_EDIT", t)}
      dividerWidth="102.2%"
    >
      <Typography
        variant="h4"
        style={{ marginBottom: "1.5rem", marginLeft: "1rem" }}
      >
        {props.customer.firstName} {props.customer.lastName}
      </Typography>
      <Grid container style={{ marginLeft: "1rem" }}>
        <Grid
          item
          md={6}
          xs={12}
          style={{ marginTop: "1rem", marginBottom: "1rem" }}
        >
          <Grid container>
            <Grid item md={4} xs={6}>
              {translate("CUSTOMER_BIRTHDAY", t)}
            </Grid>
            <Grid item md={8} xs={6}>
              <Typography style={{ fontWeight: 500 }}>
                {props.customer.birthday
                  ? new Date(props.customer.birthday).toLocaleDateString(
                      "nl-NL"
                    )
                  : "-"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
          style={{ marginTop: "1rem", marginBottom: "1rem" }}
        >
          <Grid container>
            <Grid item md={4} xs={6}>
              {translate("CUSTOMER_GENDER", t)}
            </Grid>
            <Grid item md={8} xs={6}>
              <Typography style={{ fontWeight: 500 }}>
                {props.customer.gender
                  ? genderFromEnum(props.customer.gender, t)
                  : "-"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
          style={{ marginTop: "1rem", marginBottom: "1rem" }}
        >
          <Grid container>
            <Grid item md={4} xs={6}>
              {translate("CUSTOMER_PHONE", t)}
            </Grid>
            <Grid item md={8} xs={6}>
              <Typography style={{ fontWeight: 500 }}>
                {address?.phone ?? "-"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
          style={{ marginTop: "1rem", marginBottom: "1rem" }}
        >
          <Grid container>
            <Grid item md={4} xs={6}>
              {translate("CUSTOMER_EMAIL", t)}
            </Grid>
            <Grid item md={8} xs={6}>
              <Typography style={{ fontWeight: 500 }}>
                {props.customer.email}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          md={12}
          xs={12}
          style={{ marginTop: "1rem", marginBottom: "1rem" }}
        >
          <Grid container>
            <Grid item md={2} xs={6}>
              {translate("CUSTOMER_ADDRESS", t)}
            </Grid>
            <Grid item md={10} xs={6}>
              <Typography style={{ fontWeight: 500 }}>
                {address
                  ? address?.streetAddress1 +
                    ", " +
                    address?.postalCode +
                    " " +
                    address?.city
                  : "-"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DataBox>
  );
};

export default PersonalData;
