import appsIcon from "../../assets/icons/sidebar/apps.svg";
import clientsIcon from "../../assets/icons/sidebar/clients.svg";
import homeIcon from "../../assets/icons/sidebar/home.svg";
import catalougeIcon from "../../assets/icons/sidebar/catalogue.svg";
import ordersIcon from "../../assets/icons/sidebar/orders.svg";
import exchangeIcon from "../../assets/icons/sidebar/exchange.svg";

import { translate } from "pact.drural.app-skeleton";

export const SideBarMenuItemCollection = (translations) => {
    return [
        {
            ariaLabel: "Home",
            id: "home",
            label: translate("SIDEBAR_HOME", translations),
            iconSrc: homeIcon,
            entry: process.env.REACT_APP_DASHBOARD_BASE_URI + "entry",
            url: "/"
        },
        {
            ariaLabel: "Catalog",
            id: "catalog",
            label: translate("SIDEBAR_CATALOG", translations),
            children: [
                {
                    ariaLabel: "Services",
                    id: "catalog_services",
                    label: translate("SIDEBAR_CATALOG_SERVICES", translations),
                    entry: process.env.REACT_APP_DASHBOARD_BASE_URI + "entry",
                    url: process.env.REACT_APP_DASHBOARD_BASE_URI + "products/"
                },
                {
                    ariaLabel: "Bookable resources",
                    id: "catalog_bookable",
                    label: translate("SIDEBAR_CATALOG_BOOKABLE", translations),
                    entry: process.env.REACT_APP_DASHBOARD_BASE_URI + "entry",
                    url: process.env.REACT_APP_DASHBOARD_BASE_URI + "bookable-resources/?asc=false&sort=isActive"
                }
            ],
            iconSrc: catalougeIcon
        },
        {
            ariaLabel: "Orders",
            id: "orders",
            label: translate("SIDEBAR_ORDERS", translations),
            children: [
                {
                    ariaLabel: "Orders",
                    id: "orders_orders",
                    label: translate("SIDEBAR_ORDERS", translations),
                    entry: process.env.REACT_APP_DASHBOARD_BASE_URI + "entry",
                    url: process.env.REACT_APP_DASHBOARD_BASE_URI + "orders"
                },
                {
                    ariaLabel: "Bookings",
                    id: "orders_bookings",
                    label: translate("SIDEBAR_ORDERS_BOOKINGS", translations),
                    entry: process.env.REACT_APP_DASHBOARD_BASE_URI + "entry",
                    url: process.env.REACT_APP_DASHBOARD_BASE_URI + "orders/bookings"
                },
                {
                    ariaLabel: "Drafts",
                    id: "orders_drafts",
                    label: translate("SIDEBAR_ORDERS_DRAFTS", translations),
                    entry: process.env.REACT_APP_DASHBOARD_BASE_URI + "entry",
                    url: process.env.REACT_APP_DASHBOARD_BASE_URI + "orders/drafts"
                }
            ],
            iconSrc: ordersIcon
        },
        {
            ariaLabel: "Customers",
            id: "customers",
            label: translate("SIDEBAR_CUSTOMERS", translations),
            entry: process.env.REACT_APP_DASHBOARD_BASE_URI + "entry",
            url: process.env.REACT_APP_DASHBOARD_BASE_URI + "customers/?asc=true&sort=name",
            iconSrc: clientsIcon
        },
        {
            ariaLabel: "Referrals",
            id: "referrals",
            label: translate("SIDEBAR_REFERRALS", translations),
            url: "/",
            iconSrc: exchangeIcon
        },
        // {
        //   ariaLabel: "Questionnaires",
        //   id: "questionnaires",
        //   label: translate("SIDEBAR_QUESTIONNAIRE", translations),
        //   entry: process.env.REACT_APP_QUESTIONNAIRE_BASE_URI + "entry",
        //   url: "/",
        //   iconSrc: clipboardIcon
        // },
        // {
        //   ariaLabel: "Insights",
        //   id: "insights",
        //   label:  translate("SIDEBAR_INSIGHTS", translations),
        //   entry: process.env.REACT_APP_INSIGHTS_BASE_URI + "entry",
        //   url: "/",
        //   iconSrc: statsIcon
        // },
        {
            ariaLabel: "Apps",
            id: "apps",
            label: translate("SIDEBAR_APPS", translations),
            entry: process.env.REACT_APP_DASHBOARD_BASE_URI + "entry",
            url: process.env.REACT_APP_DASHBOARD_BASE_URI + "apps/",
            iconSrc: appsIcon
        }
    ];
};