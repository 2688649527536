import { Box, Divider, Grid, Typography } from "@material-ui/core";
import * as React from "react";

const DataBox = props => {
  return (
    <Grid item md={props.mdGridWidth} xs={12} style={{ padding: "1rem" }}>
      <Box
        sx={{
          backgroundColor: "#FFFFFF",
          padding: "1.2rem",
          borderRadius: 8,
          boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.1)",
          height: props.height
        }}
      >
        <Grid container align="left">
          <Grid item md={9} xs={12}>
            <Typography
              variant="h4"
              style={{ fontWeight: 400, paddingLeft: "1rem", height: "2.5rem" }}
            >
              {props.header}
            </Typography>
          </Grid>
          {props.dropdown && (
            <Grid item md={3}>
              {props.dropdown}
            </Grid>
          )}
        </Grid>
        <Divider
          style={{
            width: props.dividerWidth,
            marginLeft: "-1.2rem",
            marginTop: "1rem",
            marginBottom: "2rem"
          }}
        />
        {props.children}
      </Box>
    </Grid>
  );
};

export default DataBox;
