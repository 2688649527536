import { Avatar, Grid, Tooltip, Typography } from "@material-ui/core";
import { ReferralStatus } from "../../enums/ReferralStatus";
import { useTranslations, translate } from "pact.drural.app-skeleton";
import { HelpOutline } from "@material-ui/icons";

const StatusGrid = ({bgColor, text, helperText}) => {
    return (            
        <Grid container>
            <Grid item md={10}>
                <Grid container>
                    <Avatar src="" style={{backgroundColor: bgColor, width: "8px", height: "8px", marginTop: ".75rem"}}> </Avatar> 
                    <Typography style={{marginLeft: "1rem"}}>{ text }</Typography>
                </Grid>
            </Grid>
            <Grid item md={2}>
                {
                    helperText && <Tooltip title={helperText}><HelpOutline fontSize="medium" /></Tooltip> 
                }
            </Grid>
        </Grid>
    );
}

const ReferralState = ({state}) => {
    const t = useTranslations();

    switch(state) {
    case ReferralStatus.NeedsApproval:
        return <StatusGrid bgColor="#000" text={translate("REFERRAL_STATE_AWAITING", t)} helperText={translate("REFERRAL_STATE_AWAITING_TOOLTIP", t)} />
    case ReferralStatus.Active:
        return <StatusGrid bgColor="#92D400" text={translate("REFERRAL_STATE_ACTIVE", t)} />
    case ReferralStatus.Pending:
        return <StatusGrid bgColor="#F9AF42" text={translate("REFERRAL_STATE_PENDING", t)} helperText={translate("REFERRAL_STATE_PENDING_TOOLTIP", t)} />
    case ReferralStatus.Cancelled:
        return <StatusGrid bgColor="#676173" text={translate("REFERRAL_STATE_CANCELLED", t)} helperText={translate("REFERRAL_STATE_CANCELLED_TOOLTIP", t)} />
    case ReferralStatus.Suspended:
        return <StatusGrid bgColor="#CC0000" text={translate("REFERRAL_STATE_SUSPENDED", t)} helperText={translate("REFERRAL_STATE_SUSPENDED_TOOLTIP", t)} />
    default:
        return <StatusGrid bgColor="#136C50" text={translate("REFERRAL_STATE_COMPLETED", t)} helperText={translate("REFERRAL_STATE_COMPLETED_TOOLTIP", t)}  />
    }
};

export default ReferralState;