import {
  Button,
  Modal,
  Box,
  Typography,
  Grid,
  TextField,
  Container,
  Divider,
  FormControlLabel,
  Radio
} from "@material-ui/core";
import * as React from "react";
import { isMobile } from "pact.drural.utils";

import { ReferralRejectionReason } from "../../enums/ReferralRejectionReason";
import { translate, useTranslations } from "pact.drural.app-skeleton";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: isMobile() ? "95%" : "70rem",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 8
};

const RejectReferralModal = ({
  handleClose,
  open,
  referral,
  rejectReferral
}) => {
  const t = useTranslations();

  const [rejectionComment, setRejectionComment] = React.useState("");
  const [rejectionReason, setRejectionReason] = React.useState(null);
  const doRejection = () => {
    let message =
      "De verwijzing werd afgewezen. \nReden: " +
      rejectionReason?.Reason +
      ".\n";
    if (rejectionComment !== "") {
      message = message + "Opmerking: " + rejectionComment;
    }

    rejectReferral(referral.id, message);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h3"
            style={{ marginBottom: "3rem", textAlign: "center" }}
          >
            {translate("REFERRAL_REJECT_HEADER", t)}
          </Typography>
          {
            <>
              <Container style={{ marginBottom: "2rem", float: "left" }}>
                <Grid container style={{ marginTop: "2rem" }}>
                  <Grid item md={3} xs={6}>
                    <Typography style={{ fontWeight: 500 }}>
                      {translate("CUSTOMER_NAME", t)}
                    </Typography>
                  </Grid>
                  <Grid item md={9} xs={6}>
                    <Typography>
                      {referral.subject.firstName} {referral.subject.lastName}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container style={{ marginTop: "2rem" }}>
                  <Grid item md={3} xs={6}>
                    <Typography style={{ fontWeight: 500 }}>
                      {translate("REFERRAL_SERVICE_PROVIDER", t)}
                    </Typography>
                  </Grid>
                  <Grid item md={9} xs={6}>
                    <Typography>{referral.to.serviceProvider}</Typography>
                  </Grid>
                </Grid>
              </Container>
              <Divider />
              <Container style={{ marginTop: "2rem", marginBottom: "2rem" }}>
                <Typography
                  variant="body2"
                  style={{ fontWeight: 600, marginBottom: "1rem" }}
                >
                  {translate("REFERRAL_REJECT_REASON_DESCRIPTION", t)}
                </Typography>
                <Grid container>
                  {ReferralRejectionReason.map((reason, i) => {
                    return (
                      <Grid item md={6} xs={12} key={i}>
                        <FormControlLabel
                          control={
                            <Radio
                              checked={reason.Code === rejectionReason?.Code}
                              onChange={() => setRejectionReason(reason)}
                            />
                          }
                          label={reason.Reason}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </Container>
              <Divider />
              <Container style={{ marginTop: "2rem" }}>
                <Typography
                  variant="body2"
                  style={{ fontWeight: 600, marginBottom: "1rem" }}
                >
                  {translate("REFERRAL_REJECT_COMMENT", t)}
                </Typography>
                <TextField
                  label={translate("REFERRAL_REJECT_COMMENT_LABEL", t)}
                  multiline
                  variant="outlined"
                  maxRows={6}
                  minRows={6}
                  fullWidth
                  style={{ marginBottom: "2rem" }}
                  value={rejectionComment}
                  onChange={event => setRejectionComment(event.target.value)}
                />
              </Container>
              <Grid container spacing={0} style={{ marginTop: "2rem" }}>
                <Grid item md={1} xs={false}></Grid>
                <Grid item md={4} xs={5}>
                  <Button onClick={handleClose} variant="outlined">
                    {translate("BUTTON_CANCEL", t)}
                  </Button>
                </Grid>
                <Grid item md={false} xs={2} />
                <Grid item md={5} xs={5}>
                  <Button
                    onClick={() => {
                      doRejection();
                    }}
                    variant="contained"
                    color="secondary"
                  >
                    {translate("REFERRAL_BUTTON_REJECT", t)}
                  </Button>
                </Grid>
              </Grid>
            </>
          }
        </Box>
      </Modal>
    </>
  );
};
export default RejectReferralModal;
