import { Button, Modal, Box, Typography, Grid, TextField } from "@material-ui/core";
import * as React from "react";
import { isMobile, validateEmail } from "pact.drural.utils";

import { translate, useTranslations } from "pact.drural.app-skeleton";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile() ? "95%" : "50rem",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 8
};

interface AddCustomerModalProps {
  open: boolean
  onClose(): void,
  referByEmail(email: string): void
}

const AddCustomerModal = ({referByEmail, open, onClose}: AddCustomerModalProps) => {
    const t = useTranslations();

    const [email, setEmail] = React.useState<string>("");
    const [isEmailValid, setEmailValid] = React.useState<boolean>(true);

    return (
        <>
            <Modal
                open={open}
                onClose={onClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h3" style={{marginBottom: "3rem", textAlign: "center"}}>
                        { translate("CUSTOMER_ADD_EMAIL_HEADER", t) }
                    </Typography>

                    <TextField 
                        label={translate("REFERRAL_CUSTOMER_EMAIL", t)} 
                        variant="outlined" 
                        maxRows={4} 
                        minRows={4}
                        error={!isEmailValid}
                        fullWidth style={{marginBottom: "2rem"}}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}/>

                    <Grid container spacing={0} style={{marginTop: "2rem"}}>
                        <Grid item md={6} xs={6}>
                            <Button onClick={onClose} variant="outlined">{translate("BUTTON_CANCEL", t)}</Button>
                        </Grid>
                        <Grid item md={false} xs={2} />
                        <Grid item md={4} xs={4}>
                            <Button onClick={() => {
                                if (!validateEmail(email)) {
                                    setEmailValid(false);
                                } else {
                                    referByEmail(email);
                                    setEmailValid(true);
                                    setEmail("");
                                    onClose();
                                }
                            }} variant="contained" color="primary">{translate("BUTTON_NEXT", t)}</Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </>
    );
};

export default AddCustomerModal;