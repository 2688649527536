import { Button, Modal, Box, Typography, Grid } from "@material-ui/core";
import * as React from "react";
import { isMobile } from "pact.drural.utils";

import { translate, useTranslations } from "pact.drural.app-skeleton";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile() ? "95%" : "50rem",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 8
};

interface WaitingForCustomerModalProps {
  open: boolean
  onClose(): void,
}

const WaitingForCustomerModal = ({open, onClose}: WaitingForCustomerModalProps) => {
    const t = useTranslations();

    return (
        <>
            <Modal
                open={open}
                onClose={onClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h3" style={{marginBottom: "3rem", textAlign: "center"}}>
                        {translate("REFERRAL_AWAITING_APPROVAL_HEADER", t)}
                    </Typography>

                    <Typography id="modal-modal-title" variant="body1" style={{marginBottom: "3rem", textAlign: "center"}}>
                        {translate("REFERRAL_AWAITING_APPROVAL_TEXT", t)}
                    </Typography>

                    <Grid container spacing={0} style={{marginTop: "2rem"}}>
                        <Grid item md={4} xs={4} />
                        <Grid item md={4} xs={4}>
                            <Button onClick={onClose} variant="contained" color="primary">{translate("BUTTON_OK", t)}</Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </>
    );
};

export default WaitingForCustomerModal;