import { Grid, Button } from "@material-ui/core";
import { ReferralStatus } from "../../enums/ReferralStatus";
import { ReferralType } from "../../enums/ReferralType";
import { useTranslations, translate } from "pact.drural.app-skeleton";

const ReferralDetailActions = ({referral, onCancel, onComplete, onAccept, onReject}) => {
    const t = useTranslations();

    if (referral.isSelfReferral) {
        switch(referral.status) {
        case ReferralStatus.Active:
            return <>
                <Grid item md={4} xs={6} />
                <Grid item md={4} xs={6}>
                    <Button 
                        variant="contained" 
                        color="primary" 
                        onClick={() => onComplete(referral.id) }
                    >
                        { translate("REFERRAL_ACTION_COMPLETE", t) }
                    </Button>
                </Grid>
            </>;
        case ReferralStatus.Pending:
            return <>
                <Grid item md={4} xs={6}>
                    <Button variant="contained" color="secondary" onClick={() => onCancel(referral.id) }>{ translate("REFERRAL_ACTION_CANCEL", t) }</Button>
                </Grid>
                <Grid item md={4} xs={6}>
                    <Button variant="contained" color="primary" onClick={() => onAccept(referral.id) }>{ translate("REFERRAL_ACTION_ACCEPT", t) }</Button>
                </Grid>
            </>;

        default:
            return <></>;      
        } 
    }

    if (referral.type === ReferralType.Received) {
        switch(referral.status) {
        case ReferralStatus.Active:
            return <>
                <Grid item md={4} xs={6} />
                <Grid item md={4} xs={6}>
                    <Button 
                        variant="contained" 
                        color="primary" 
                        onClick={() => onComplete(referral.id) }
                    >
                        { translate("REFERRAL_ACTION_COMPLETE", t) }
                    </Button>
                </Grid>
            </>;
        case ReferralStatus.Pending:
            return <>
                <Grid item md={4} xs={6}>
                    <Button variant="contained" color="secondary" onClick={() => onReject() }>{ translate("REFERRAL_ACTION_REJECT", t) }</Button>
                </Grid>
                <Grid item md={4} xs={6}>
                    <Button variant="contained" color="primary" onClick={() => onAccept(referral.id) }>{ translate("REFERRAL_ACTION_ACCEPT", t) }</Button>
                </Grid>
            </>;

        default:
            return <></>;      
        }
    }

    if (referral.type === ReferralType.Sent) {
        switch(referral.status) {
        case ReferralStatus.Active:
            return <>
                <Grid item md={4} xs={6} />
                <Grid item md={4} xs={6}>
                    <Button 
                        variant="contained" 
                        color="secondary" 
                        onClick={() => onCancel(referral.id) }
                    >
                        { translate("REFERRAL_ACTION_CANCEL", t) }
                    </Button>
                </Grid>
            </>;
        case ReferralStatus.Pending:
            return <>
                <Grid item md={4} xs={6} />
                <Grid item md={4} xs={6}>
                    <Button 
                        variant="contained" 
                        color="secondary"
                        onClick={() => onCancel(referral.id) }
                    >
                        { translate("REFERRAL_ACTION_CANCEL", t) }
                    </Button>
                </Grid>
            </>;
        default:
            return <></>;      
        }
    }

    return <></>;
};

export default ReferralDetailActions;