import { combineReducers } from "redux";

import customerReducer from "./customerReducer";
import modalReducer from "./modalReducer";
import referralReducer from "./referralReducer";
import errorReducer from "./errorReducer";

import { notificationReducer } from "pact.drural.notifications";
import { navigationReducer, backdropReducer, userReducer, authenticationReducer, localizationReducer } from "pact.drural.app-skeleton";
import { serviceReducer } from "pact.drural.ui";


const rootReducer = combineReducers({
    navigation: navigationReducer,
    modal: modalReducer,
    customer: customerReducer,
    service: serviceReducer,
    referral: referralReducer,
    error: errorReducer,
    
    notification: notificationReducer,
    backdrop: backdropReducer,
    authentication: authenticationReducer,
    user: userReducer,
    localization: localizationReducer
});

export default rootReducer;